import React from "react"
import BasicLayout from "../layouts/basic-layout"
import styled from "styled-components"
import style from "../assets/global-style"

const JobBox = styled.div`
  ${style.flexBox("column", "center")}

  .item {
    ${style.flexBox("column", "center")}
    .title {
      font-size: 2.1rem;
      color: ${style["theme-color"]};
    }

    .desc {
      margin-top: 36px;
      font-size: 1.4rem;
      color: ${style["theme-color"]};
    }

    ul {
      padding: 0;
      margin-top: 10px;
    }

    li {
      font-size: 1rem;
      list-style: none;
      line-height: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    .item {
      .title {
        font-size: 1.5rem;
      }

      .desc {
        margin-top: 16px;
        font-size: 1rem;
      }

      ul {
        padding: 20px;
      }

      li {
        font-size: 0.8rem;
      }
    }


  }
`

const data = [
  {
    desc: "薪资区间：4500 - 5900",
    details: [
      "福利相关：带薪年假、专业培训、节日福利、包住宿、",
      "餐饮补贴、购买社保、生日福利、员工体检、其他福利等",
    ],
  },
  {
    desc: "职位信息",
    details: [
      "此职位为云南城建物业集团下属春城大观分公司招聘",
      "工作地点：昆明市官渡区官南大道康乐茶城附近",
    ],
  },
  {
    desc: "岗位职责",
    details: [
      "1、按照客服作业程序、标准、规范，完成客服展示与服务工作；",
      "2、积极配合销售人员工作；",
      "3、热情友好，耐心细致的为到访客户提供询问、指引等服务；",
      "4、主动招呼进入售楼处的客户，并提供其所需资料饮料并做好消耗记录台账；",
      "5、对部门相关文件资料的归档与整理；",
      "6、完成上级领导交办的其他工作。",
    ],
  },
  {
    desc: "任职资格",
    details: [
      "1、大专以上学历，有客户接待和服务经验者优先；",
      "2、普通话标准，口齿清楚，声音甜美，优秀的语言表达能力和沟通能力；",
      "3、较强的应变能力、协调能力，能独立处理紧急问题；",
      "4、良好的服务意识、耐心和责任心，工作积极主动；",
      "5、形象气质佳，热爱客服服务事业。",
    ],
  },
  {
    desc: "（身高165cm - 170cm，年龄20 - 28岁）",
    details: [],
  },
]

const Page = () => (
  <BasicLayout
    title="加入我们"
    bannerImg="http://maps.yncce.cn/website/join-us.png"
  >
    <JobBox>
      <div className="item">
        <h4 className="title">招聘岗位：营销中心客服 (招聘10人)</h4>
        {data.map(item => (
          <>
            <div className="desc">{item.desc}</div>
            <ul>
              {item.details.map(d => (
                <li>{d}</li>
              ))}
            </ul>
          </>
        ))}
      </div>
    </JobBox>
  </BasicLayout>
)

export default Page
